<template>
  <div class="container">
    <webHeaderVue @scrollToHandle="scrollToHandle"></webHeaderVue>
    <webMainVue class="webMainVue"></webMainVue>
    <webFooterVue></webFooterVue>
  </div>
</template>
<script setup>
import webHeaderVue from "./components/web-header.vue";
import webMainVue from "./components/web-main.vue";
import webFooterVue from "./components/web-footer.vue";

const mapScroll = {
  app: 0,
  condition: 1000,
  about: 1600,
  contact: 10000,
};
const scrollToHandle = type => {
  document.documentElement.scrollTop = mapScroll[type];
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
</style>
