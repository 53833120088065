<template>
  <div class="main">
    <div class="main-content">
      <div class="main-center">
        <div class="center-left">
          <p>Syarat dan Ketentuan</p>
          <div class="condition">
            <div>
              <img src="../assets/img/icon_select.png" alt="" />
              <span>Memiliki KTP </span>
            </div>
            <div>
              <img src="../assets/img/icon_select.png" alt="" />
              <span>Memiliki rekening bank </span>
            </div>
            <div>
              <img src="../assets/img/icon_select.png" alt="" />
              <span>Penghasilan stabil </span>
            </div>
            <div>
              <img src="../assets/img/icon_select.png" alt="" />
              <span>Warga Negara Indonesia, minimal 18 tahun </span>
            </div>
          </div>
        </div>
        <div class="center-right">
          <img style="width: 100%; height: 100%" src="../assets/img/img1.png" alt="" />
        </div>
      </div>
      <div class="main-bottom">
        <img style="width: 50%; height: 40%" src="../assets/img/img2.png" alt="" />
        <div>
          <p>Tentang kami</p>
          <p>
            Indosaku-Pinjaman Kredit Online Dana adalah aplikasi pinjaman online, silakan isi data diri Anda saat mengajukan, pinjaman Anda dan akan
            diverifikasi dalam waktu 12 jam
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup></script>

<style lang="scss" scoped>
.main {
  background-color: #fff;
  width: 100%;
  // margin-top: 150px;
}
.main-content {
  z-index: 100;
  width: 1200px;
  margin: 0 auto;

  .main-center {
    margin: 0 auto;
    width: 85%;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    padding-bottom: 130px;
    .center-left {
      margin-top: 54px;
      p:first-child {
        font-size: 36px;
        font-weight: 500;
        color: #2d2d2d;
        margin-bottom: 44px;
      }
      .condition {
        div {
          display: flex;
          align-items: center;
          margin-bottom: 13px;
          img {
            width: 24px;
            height: 24px;
            margin-right: 20px;
          }
        }
      }
    }
  }
  .main-bottom {
    display: flex;
    padding-bottom: 100px;
    div {
      margin: 109px 0 0 100px;
      p:first-child {
        font-size: 36px;
        font-weight: 500;
        margin-bottom: 28px;
        color: #2d2d2d;
      }
      p:last-child {
        font-size: 14px;
        line-height: 24px;
        width: 38%;
      }
    }
  }
}
</style>
