<template>
  <div class="header">
    <img style="width: 100%" src="../assets/img/banner.png" alt="" />
    <header class="content-header">
      <div class="content-left">
        <img src="../assets/img/newlogo_48.png" style="height: 48px; width: 48px" />
        <span>Indosaku-Pinjaman Kredit Online Dana</span>
      </div>
      <div class="content-right">
        <div @click="scrollToHandle('app')">Pengenalan aplikasi</div>
        <div @click="scrollToHandle('condition')">Syarat dan Ketentuan</div>
        <div @click="scrollToHandle('about')">Tentang kami</div>
        <div @click="scrollToHandle('contact')">Hubungi kami</div>
      </div>
    </header>
    <section class="content-section">
      <div class="content-title">
        <p>Proses mudah</p>
        <p>Pencairan cepat</p>
        <p class="content-description">Platform layanan pinjaman, produk bervariasi, pinjaman fleksibel</p>
        <!-- <a class="downloadApp" href="#" target="_blank"><img src="../assets/img/icon_download.png" alt="" /></a> -->
      </div>
      <img src="../assets/img/img3.png" alt="" />
    </section>
    <section class="content-top">
      <div class="main-top">
        <div class="content-margin">
          <div>
            <img src="../assets/img/icon_loan.png" alt="" />
            <p>Peminjaman</p>
            <p>Lengkapi informasi dalam 5 menit, pinjaman mudah</p>
          </div>
          <div>
            <img src="../assets/img/icon_service.png" alt="" />
            <p>Pelayanan baik</p>
            <p>Mengingatkan untuk melakukan pembayaran tepat waktu</p>
          </div>
          <div>
            <img src="../assets/img/icon_pay.png" alt="" />
            <p>Pembayaran langsung</p>
            <p>Verifikasi berhasil, akan dicairkan segera ke rekening</p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script setup>
import { defineEmits } from "vue";
const emits = defineEmits(["scrollToHandle"]);
const scrollToHandle = type => {
  emits("scrollToHandle", type);
};
</script>

<style lang="scss" scoped>
.header {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  // height: 1350px;

  .content-header {
    width: 1200px;
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #ffffff;
    font-size: 16px;
    font-weight: 500;
    font-family: Inter-Semi Bold, Inter;
    padding: 28px 0;
    .content-left {
      display: flex;
      align-items: center;
      > img {
        width: 48px;
        height: 48px;
        margin-right: 17px;
        height: 100%;
        width: 100%;
      }
    }
    .content-right {
      display: flex;
      color: #f5f5f5;
      width: 55%;
      justify-content: space-between;
      div {
        &:first-child {
          color: #fff;
        }
        &:hover {
          font-weight: normal;
          color: #fff;
          cursor: pointer;
        }
      }
    }
  }
  .content-section {
    display: flex;
    width: 1200px;
    position: relative;
    bottom: 833px;
    > img {
      z-index: 101;
      display: block;
      margin-left: 130px;
      position: absolute;
      right: 45px;
      top: -20px;
      width: 536px;
      height: 606px;
      // width: 100%;
    }
    .content-title {
      display: flex;
      flex-direction: column;
      margin-top: 78px;
      width: 50%;
      p {
        font-size: 65px;
        font-weight: bold;
        color: #ffffff;
      }
      P:first-child {
        margin-bottom: 30px;
      }
      .content-description {
        margin: 30px 0;
        font-size: 24px;
        font-weight: 400;
        color: #f7f7f7;
        line-height: 28px;
      }
      .downloadApp {
        // display: block;
        width: 250px;
        height: 74px;
        cursor: pointer;
      }
    }
  }
  .content-top {
    position: absolute;
    top: 720px;
    .main-top {
      width: 1200px;
      background: #ffffff;
      box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.1);
      border-radius: 10px 10px 10px 10px;
      padding-top: 55px;

      .content-margin {
        padding: 65px 45px;
        display: flex;
        justify-content: space-between;
        div {
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          img {
            width: 100px;
            height: 100px;
          }
          p:nth-child(2) {
            font-size: 32px;
            font-weight: 500;
            color: #2d2d2d;
            margin-top: 10px;
          }
          P:nth-child(3) {
            font-size: 20px;
            color: #9d9d9d;
            margin: 20px 50px 90px 50px;
          }
        }
      }
    }
  }
}
</style>
