<template>
  <footer class="footer">
    <div class="footer-content">
      <div class="footer-left">
        <p>Nomor yang bisa dihubungi</p>
        <p>082114133395</p>
        <p>Email：yinniind@gmail.com</p>
        <p>Nama perusahaan： PT. SENS KARYA TEKNOLOGI</p>
        <p>Alamat perusahaan：Jl. Imam Mahbud Kh. Moch Mansyur No. 20. Kec. Gambir, Kel. Duri Pulo Central Jakarta DKI Jakarta</p>
      </div>
      <div class="footer-right">
        <img src="../assets/img/newlogo_72.png" alt="" />
        <p>Indosaku-Pinjaman Kredit Online Dana</p>
        <!-- <a href="#" target="_blank"><p>Perjanjian Privasi</p></a> -->
      </div>
    </div>
  </footer>
</template>

<script setup></script>

<style lang="scss" scoped>
.footer {
  background: #18202e;
  padding: 40px 0 54px 0;
  width: 100%;
  .footer-content {
    margin: 0 auto;
    display: flex;
    box-sizing: border-box;
    justify-content: space-between;
    width: 1200px;
    font-size: 14px;
    font-family: Inter-Medium, Inter;
    font-weight: 500;
    color: #ffffff;
    .footer-left {
      p {
        font-size: 12px;
        font-weight: 400;
        margin-bottom: 11px;
      }
      p:nth-child(1) {
        font-size: 14px;
        font-weight: 500;
      }
      p:nth-child(2) {
        font-size: 22px;
        font-weight: 500;
        margin: 9px 0 18px 0;
      }
    }
    .footer-right {
      margin-right: 52px;
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        width: 70px;
        height: 70px;
      }
      p:nth-child(2) {
        font-size: 12px;
        padding: 12px 0 26px 0;
      }
      p:last-child {
        font-size: 14px;
        // color: #35cb02;
      }
    }
  }
}
</style>
